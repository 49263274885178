<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <base-material-card
          color="primary"
          icon="mdi-map-outline"
          inline
          class="px-5 py-3"
        >
          <template v-slot:after-heading>
            <div class="display-1 font-weight-light">
              Visualizar mapa
              <span v-if="tipoSimbologia === 'SIMPLES'">
                de simbologia simples
              </span>
              <span v-else-if="tipoSimbologia === 'CATEGORIZADA'">
                de simbologia categorizada
              </span>
              <span v-else-if="tipoSimbologia === 'GRADUADA'">
                de simbologia graduada
              </span>
              <span v-else> de calor </span>
              para o layer: {{ relatorio.nome }}
            </div>
          </template>
          <v-row
            align="center"
            no-gutters
            class="ml-0"
          >
            <v-col cols="12">
              <v-alert
                dense
                outlined
                type="info"
                color="blue"
                class="mt-3 mb-3 py-1 px-1"
              >
                Exibindo {{ feicoesExibidas | parseNumberToIntegerBR }} de um
                total de
                {{ relatorio.numero_registros | parseNumberToIntegerBR }}
                feições. Máximo a serem plotadas:
                {{ maximoFeicoes | parseNumberToIntegerBR }} feições. Para
                visualizar mais feições, por favor, utilize a ferramenta
                <router-link :to="'/mapas/entidades-geo-bdgd/visualizar-mapa'"
                  >Entidades geográficas da BDGD</router-link
                >.
              </v-alert>
            </v-col>
            <v-col cols="12">
              <div id="visualizar-mapa-layers-personalizados"></div>
            </v-col>
          </v-row>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import L from 'leaflet';
import MapboxConfig from '@/config/MapboxConfig';
import mapbox from '@/config/Mapbox';
import RelatoriosService from '@/services/RelatoriosService';
import GeoServerService from '@/services/GeoServerService';
import mapFeaturesStylesMixins from '@/mixins/mapFeaturesStylesMixins';
import mapConfigMixins from '@/mixins/mapConfigMixins';
import geocoderMixins from '@/mixins/leaflet/geocoderMixins';
import browserPrintMixins from '@/mixins/leaflet/browserPrintMixins';
import scaleMixins from '@/mixins/leaflet/scaleMixins';
import drawMixins from '@/mixins/leaflet/drawMixins';
import filtersMixins from '@/mixins/geoserver/filtersMixins';
import dayjs from 'dayjs';
import '@/plugins/leaflet-icon-glyph-v2';

const mapboxConfig = new MapboxConfig(mapbox.access_token, mapbox.style);

export default {
  mixins: [
    mapFeaturesStylesMixins,
    mapConfigMixins,
    geocoderMixins,
    browserPrintMixins,
    scaleMixins,
    drawMixins,
    filtersMixins
  ],

  data: () => ({
    relatorio: {
      nome: ''
    },
    simbologiaConfig: {},
    tipoSimbologia: 'SIMPLES',
    dados: {
      arat: null
    },
    map: null,
    mapaId: 'visualizar-mapa-layers-personalizados',
    layer: null,
    layerControl: null,
    overlayLayer: null,
    feicoesExibidas: 0,
    timeoutControl: null
  }),

  mounted() {
    this.getRelatorio(this.$route.params.id);
    this.configuraMapa(4);
    this.setView();
  },

  computed: {
    maximoFeicoes() {
      return this.getMapConfigMaxFeatures ?? 5000;
    }
  },

  watch: {
    relatorio() {
      const numeroFeicoes = this.relatorio.numero_registros;
      if (numeroFeicoes > this.maximoFeicoes) {
        this.plotaFeicoesComPaginacao();
      } else {
        this.plotaFeicoesSemPaginacao();
      }
    }
  },

  methods: {
    getRelatorio(id) {
      RelatoriosService.getRelatorio(id)
        .then((response) => {
          this.relatorio = response.data.data;
          this.simbologiaConfig = JSON.parse(this.relatorio.simbologia_config);
          this.tipoSimbologia = this.relatorio.tipo_simbologia;
          this.criaLegenda();
        })
        .catch(() =>
          this.$toast.error('Erro ao recuperar o layer.', '', {
            position: 'topRight'
          })
        );
    },

    getDadosLayer(bbox = '') {
      let {
        geoserver_layer: layer,
        geoserver_fields: fields,
        geoserver_cql_filter: cqlFilter,
        geoserver_ogc_filter: ogcFilter
      } = this.relatorio;

      this.feicoesExibidas = 0;

      this.map.spin(true);

      return GeoServerService.getDadosLayer(
        layer,
        fields,
        this.isCqlFilter() ? cqlFilter : ogcFilter,
        bbox,
        this.maximoFeicoes,
        'application/json',
        this.getFilterType()
      );
    },

    configuraMapa(zoom) {
      this.map = L.map(this.mapaId, {
        fullscreenControl: true,
        loadingControl: true,
        layers: [mapboxConfig.getDefaultLayer()],
        zoom
      });

      this.layerControl = L.control.layers(mapboxConfig.getBaseLayers());
      this.layerControl.addTo(this.map);

      this.createScaleControl(L).addTo(this.map);
      this.createGeocoderControl(L, this.map).addTo(this.map);
      this.createBrowserPrintControl(L, 'Mapa Layer Personalizado').addTo(
        this.map
      );
      this.configDrawControl(L);
    },

    configDrawControl(L) {
      let { drawnItems, drawControl } = this.createDrawControl(L);

      this.map.addLayer(drawnItems);
      this.map.addControl(drawControl);

      this.map.on('draw:created', ({ layer: polygon }) => {
        drawnItems.addLayer(polygon);

        // Efetua o download do arquivo com as feições dentro do poligono criado
        const arrLatLngs = polygon.getLatLngs();

        this.downloadCsvFeaturesWithinPolygon(
          this.relatorio.geoserver_layer,
          arrLatLngs[0]
        );
      });

      this.map.on('draw:edited', ({ layers: polygons }) => {
        polygons.eachLayer((polygon) => {
          // Efetua o download do arquivo com as feições dentro do poligono criado
          const arrLatLngs = polygon.getLatLngs();

          this.downloadCsvFeaturesWithinPolygon(
            this.relatorio.geoserver_layer,
            arrLatLngs[0]
          );
        });
      });
    },

    downloadCsvFeaturesWithinPolygon(layerName, polygonLatLngs) {
      this.$toast.info('Preparando seu arquivo para download.', '', {
        position: 'topRight',
        timeout: 2000
      });

      const promise = this.isCqlFilter()
        ? GeoServerService.downloadCsvFeaturesWithinPolygon(
            layerName,
            polygonLatLngs,
            this.relatorio.geoserver_cql_filter,
            0 // maxFeatures
          )
        : GeoServerService.downloadCsvFeaturesWithinPolygonUsingOgcFilter(
            layerName,
            polygonLatLngs,
            this.relatorio.geoserver_ogc_filter,
            0 // maxFeatures
          );

      promise
        .then((res) => {
          const timestamp = dayjs(new Date()).format('YYYYMMDDHHmm');
          const nomeArquivo = `${layerName}_feicoes_selecionadas_${timestamp}.csv`;
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', nomeArquivo);
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => {
          this.$toast.error(
            'Erro ao baixar o arquivo CSV com as feições selecionadas.',
            '',
            { position: 'topRight' }
          );
          console.error('Erro: ', error);
        });
    },

    setView(latitude = -2.5606242, longitude = -44.4016494) {
      this.map.setView([latitude, longitude]);
    },

    async plotaFeicoes(geojson) {
      if (!geojson.numberReturned) {
        this.map.spin(false);
        return;
      }

      this.feicoesExibidas = geojson.numberReturned;

      if (this.tipoSimbologia === 'MAPA_CALOR') {
        return this.plotaMapaCalor(geojson);
      }

      const opacity = this.getMapFeatureOpacity;
      const fillOpacity = this.getMapFeatureFillOpacity;
      const weight = this.getMapFeatureWeight;
      let iconColor = null;

      let layer = L.geoJSON(geojson, {
        style: (feature) => {
          switch (this.tipoSimbologia) {
            case 'SIMPLES':
              var color = this.simbologiaConfig.corFeicoes;

              return {
                color,
                fillColor: color,
                opacity,
                fillOpacity,
                weight
              };

            case 'CATEGORIZADA':
              var { campoParaClassificacao } = this.simbologiaConfig;
              var dadoParaClassificacao =
                feature.properties[campoParaClassificacao];
              var color = this.getIconeCorFeicaoSimbologiaCategorizada(
                dadoParaClassificacao
              ).cor;

              return {
                color,
                fillColor: color,
                opacity,
                fillOpacity,
                weight
              };

            case 'GRADUADA':
              var { campoParaClassificacao } = this.simbologiaConfig;
              var dadoParaClassificacao =
                feature.properties[campoParaClassificacao];
              var color = this.getIconeCorFeicaoSimbologiaGraduada(
                dadoParaClassificacao
              ).cor;

              return {
                color,
                fillColor: color,
                opacity,
                fillOpacity,
                weight
              };

            default:
              return {
                opacity,
                fillOpacity,
                weight
              };
          }
        },

        onEachFeature: (feature, layer) => {
          const msgPopup = this.msgPopup(feature.properties);
          if (msgPopup) {
            layer.bindPopup(msgPopup);
          }
        },

        pointToLayer: (feature, latlng) => {
          switch (this.tipoSimbologia) {
            case 'SIMPLES':
              iconColor = {
                cor: this.simbologiaConfig.corFeicoes,
                icone: this.simbologiaConfig.iconeFeicoes
              };
              break;
            case 'GRADUADA':
              let dadoParaClassificacaoGraduada =
                feature.properties[
                  this.simbologiaConfig.campoParaClassificacao
                ];
              iconColor = this.getIconeCorFeicaoSimbologiaGraduada(
                dadoParaClassificacaoGraduada
              );
              break;
            case 'CATEGORIZADA':
              let dadoParaClassificacaoCategorizada =
                feature.properties[
                  this.simbologiaConfig.campoParaClassificacao
                ];
              iconColor = this.getIconeCorFeicaoSimbologiaCategorizada(
                dadoParaClassificacaoCategorizada
              );
              break;
          }

          if (this.tipoSimbologia == 'MAPA_CALOR' || !iconColor.icone) {
            return L.circleMarker(latlng, {
              radius: this.getMapFeatureRadius
            });
          }

          return L.marker(latlng, {
            icon: L.icon.glyph({
              iconMDI: iconColor.icone,
              colorBackground: iconColor.cor
            })
          });
        }
      });

      if (this.layer) {
        this.map.removeLayer(this.layer);
      }

      layer.addTo(this.map);
      this.layer = layer;

      const numeroFeicoes = this.relatorio.numero_registros;

      if (numeroFeicoes <= this.maximoFeicoes)
        this.map.fitBounds(layer.getBounds());

      this.map.spin(false);
    },

    async plotaFeicoesComPaginacao() {
      // Tenta obter a localização do usuário ...
      this.map.locate({
        setView: true,
        maxZoom: 18
      });

      // ... em caso de sucesso, utiliza a localização para exibir o mapa
      this.map.on('locationfound', async () => {
        this.map.setZoom(18);
        const bounds = this.map.getBounds();
        const response = await this.getDadosLayer(bounds.toBBoxString());
        const geojson = response.data;
        this.plotaFeicoes(geojson);

        this.map.on('moveend', () => {
          // Cancela o request anterior, caso ele exista
          if (this.timeoutControl) clearTimeout(this.timeoutControl);

          // Espera um pouco antes de fazer novamente a requisição, pois o usuário pode movimentar muito o mapa para encontrar um local
          this.timeoutControl = setTimeout(async () => {
            const bounds = this.map.getBounds();
            const response = await this.getDadosLayer(bounds.toBBoxString());
            const geojson = response.data;
            this.plotaFeicoes(geojson);
          }, 2000);
        });
      });

      // ... em caso de falha, utiliza a área de concessão da empresa para calcular o ponto a ser o centro do mapa
      this.map.on('locationerror', async (e) => {
        console.log(
          'Erro ao tentar recuperar a localização do usuário: ' + e.message
        );

        // ... usa a capital do estado como ponto central
        const capital = this.$store.getters.getCompanyCapitalCoordinates;
        this.setView(capital[1], capital[0]);

        setTimeout(async () => {
          this.map.setZoom(18);
          const bounds = this.map.getBounds();
          const response = await this.getDadosLayer(bounds.toBBoxString());
          const geojson = response.data;
          this.plotaFeicoes(geojson);

          this.map.on('moveend', () => {
            // Cancela o request anterior, caso ele exista
            if (this.timeoutControl) clearTimeout(this.timeoutControl);

            // Espera um pouco antes de fazer novamente a requisição, pois o usuário pode movimentar muito o mapa para encontrar um local
            this.timeoutControl = setTimeout(async () => {
              const bounds = this.map.getBounds();
              const response = await this.getDadosLayer(bounds.toBBoxString());
              const geojson = response.data;
              this.plotaFeicoes(geojson);
            }, 2000);
          });
        }, 2000);
      });
    },

    async plotaFeicoesSemPaginacao() {
      const response = await this.getDadosLayer();
      const geojson = response.data;
      this.plotaFeicoes(geojson);
    },

    msgPopup(linha) {
      let msg = '';

      Object.keys(linha).forEach((campo) => {
        const dado = linha[campo];
        if (campo === 'geom' || dado === '' || dado === null) return;
        msg += `<b>${campo}:</b> ${linha[campo]}<br>`;
      });

      return msg;
    },

    getIconeCorFeicaoSimbologiaCategorizada(dadoParaClassificacao) {
      const { iconesCoresFeicoes, iconeCorOutrasFeicoes } =
        this.simbologiaConfig;

      return (
        iconesCoresFeicoes[
          dadoParaClassificacao !== null ? dadoParaClassificacao.trim() : null
        ] || iconeCorOutrasFeicoes
      );
    },

    getIconeCorFeicaoSimbologiaGraduada(dadoParaClassificacao) {
      const { iconesCoresFeicoes, iconeCorOutrasFeicoes } =
        this.simbologiaConfig;

      let iconeCorFeicoes = iconeCorOutrasFeicoes;

      dadoParaClassificacao = Number(dadoParaClassificacao);

      let infoFeicoes = iconesCoresFeicoes.find((info) => {
        let inicio = Number(info.range[0]);
        let fim = Number(info.range[1]);

        return dadoParaClassificacao >= inicio && dadoParaClassificacao <= fim;
      });

      if (infoFeicoes) {
        iconeCorFeicoes = { icone: infoFeicoes.icone, cor: infoFeicoes.cor };
      }

      return iconeCorFeicoes;
    },

    plotaMapaCalor(geojson) {
      this.plotaPontosComPopup(geojson);

      const { raioPontos, desfoquePontos, campoParaClassificacao } =
        this.simbologiaConfig;

      let max = 1.0;

      const points = geojson.features.map((feature) => {
        let dadoParaClassificacao = feature.properties[campoParaClassificacao];
        let point = feature.geometry;

        if (dadoParaClassificacao > max) {
          max = dadoParaClassificacao;
        }

        return [...point.coordinates.reverse(), dadoParaClassificacao];
      });

      if (this.layer) {
        this.map.removeLayer(this.layer);
      }

      // minOpacity - the minimum opacity the heat will start at
      // maxZoom - zoom level where the points reach maximum intensity (as intensity scales with zoom), equals maxZoom of the map by default
      // max - maximum point intensity, 1.0 by default
      // radius - radius of each "point" of the heatmap, 25 by default
      // blur - amount of blur, 15 by default
      // gradient - color gradient config, e.g. {0.4: 'blue', 0.65: 'lime', 1: 'red'}

      this.layer = L.heatLayer(points, {
        maxZoom: 7,
        max,
        radius: raioPontos,
        blur: desfoquePontos
      });

      this.layer.addTo(this.map);
      this.map.spin(false);
    },

    plotaPontosComPopup(geojson) {
      const color = this.getMapFeatureColor;
      const opacity = this.getMapFeatureOpacity;
      const fillOpacity = this.getMapFeatureFillOpacity;
      const weight = this.getMapFeatureWeight;

      let overlayLayer = L.geoJSON(geojson, {
        style: () => {
          return {
            color,
            fillColor: color,
            opacity,
            fillOpacity,
            weight
          };
        },

        onEachFeature: (feature, layer) => {
          const msgPopup = this.msgPopup(feature.properties);
          if (msgPopup) {
            layer.bindPopup(msgPopup);
          }
        },

        pointToLayer: (feature, latlng) => {
          return L.circleMarker(latlng, {
            radius: this.getMapFeatureRadius
          });
        }
      });

      if (this.overlayLayer) {
        this.map.removeLayer(this.overlayLayer);
        this.layerControl.removeLayer(this.overlayLayer);
      }

      this.overlayLayer = overlayLayer;

      this.layerControl.addOverlay(this.overlayLayer, 'Pontos');
    },

    criaLegenda() {
      if (this.tipoSimbologia === 'CATEGORIZADA') {
        return this.criaLegendaSimbologiaCategorizada();
      }

      if (this.tipoSimbologia === 'GRADUADA') {
        return this.criaLegendaSimbologiaGraduada();
      }

      if (this.tipoSimbologia === 'MAPA_CALOR') {
        return this.criaLegendaSimbologiaMapaCalor();
      }
    },

    criaLegendaSimbologiaCategorizada() {
      const {
        iconesCoresFeicoes,
        iconeCorOutrasFeicoes,
        campoParaClassificacao
      } = this.simbologiaConfig;

      const legend = L.control({ position: 'bottomright' });

      legend.onAdd = () => {
        const div = L.DomUtil.create('div', 'mapa-info mapa-legend');

        if (campoParaClassificacao) {
          div.innerHTML += `<h4>${campoParaClassificacao}</h4>`;
        }

        for (let dado in iconesCoresFeicoes) {
          let infoFeicao = this.getIconeCorFeicaoSimbologiaCategorizada(dado);

          let bgColor1 = infoFeicao.icone ? infoFeicao.cor : 'white';
          let bgColor2 = infoFeicao.icone ? 'white' : infoFeicao.cor;

          div.innerHTML += `
              <div style="display:flex; align-items: center; margin: 3px 0px">
                <span style="height: 23px; background-color: ${bgColor1}">
                  <div style="display: inline-flex; justify-content: center; height: 23px; width: 23px; background-color: ${bgColor2}; border-radius: 50%; border: 2px solid ${infoFeicao.cor}">
                    <i style="margin: auto; font-size: 17px; color: black" class="v-icon notranslate mdi ${infoFeicao.icone}"></i>
                  </div>
                </span>&nbsp;${dado}
              </div>
            `;
        }

        let bgColor1 = iconeCorOutrasFeicoes.icone
          ? iconeCorOutrasFeicoes.cor
          : 'white';
        let bgColor2 = iconeCorOutrasFeicoes.icone
          ? 'white'
          : iconeCorOutrasFeicoes.cor;

        div.innerHTML += `
            <div style="display:flex; align-items: center">
              <span style="background-color: ${bgColor1}">
                <div style="display: inline-flex; justify-content: center; height: 23px; width: 23px; background-color: ${bgColor2}; border-radius: 50%; border: 2px solid ${iconeCorOutrasFeicoes.cor}">
                  <i style="margin: auto; font-size: 17px; color: black" class="v-icon notranslate mdi ${iconeCorOutrasFeicoes.icone}"></i>
                </div>
              </span>&nbsp;Outros
            </div>
          `;

        return div;
      };

      legend.addTo(this.map);
    },

    criaLegendaSimbologiaGraduada() {
      const {
        iconesCoresFeicoes,
        iconeCorOutrasFeicoes,
        campoParaClassificacao
      } = this.simbologiaConfig;

      const legend = L.control({ position: 'bottomright' });

      legend.onAdd = () => {
        const div = L.DomUtil.create('div', 'mapa-info mapa-legend');

        if (campoParaClassificacao) {
          div.innerHTML += `<h4>${campoParaClassificacao}</h4>`;
        }

        iconesCoresFeicoes.forEach((infoFeicao) => {
          let [inicio, fim] = infoFeicao.range;

          let bgColor1 = infoFeicao.icone ? infoFeicao.cor : 'white';
          let bgColor2 = infoFeicao.icone ? 'white' : infoFeicao.cor;

          div.innerHTML += `
              <div style="display:flex; align-items: center; margin: 3px 0px">
                <span style="height: 23px; background-color: ${bgColor1}">
                  <div style="display: inline-flex; justify-content: center; height: 23px; width: 23px; background-color: ${bgColor2}; border-radius: 50%; border: 2px solid ${infoFeicao.cor}">
                    <i style="margin: auto; font-size: 17px; color: black" class="v-icon notranslate mdi ${infoFeicao.icone}"></i>
                  </div>
                </span>&nbsp;${inicio} -  ${fim}
              </div>
            `;
        });

        let bgColor1 = iconeCorOutrasFeicoes.icone
          ? iconeCorOutrasFeicoes.cor
          : 'white';
        let bgColor2 = iconeCorOutrasFeicoes.icone
          ? 'white'
          : iconeCorOutrasFeicoes.cor;

        div.innerHTML += `
            <div style="display:flex; align-items: center">
              <span style="background-color: ${bgColor1}">
                <div style="display: inline-flex; justify-content: center; height: 23px; width: 23px; background-color: ${bgColor2}; border-radius: 50%; border: 2px solid ${iconeCorOutrasFeicoes.cor}">
                  <i style="margin: auto; font-size: 17px; color: black" class="v-icon notranslate mdi ${iconeCorOutrasFeicoes.icone}"></i>
                </div>
              </span>&nbsp;Outros
            </div>
          `;

        return div;
      };

      legend.addTo(this.map);
    },

    criaLegendaSimbologiaMapaCalor() {
      const { campoParaClassificacao } = this.simbologiaConfig;

      const legend = L.control({ position: 'bottomright' });

      legend.onAdd = () => {
        const div = L.DomUtil.create('div', 'mapa-info mapa-legend');

        if (campoParaClassificacao) {
          div.innerHTML += `<h4>${campoParaClassificacao}</h4>`;
        }

        return div;
      };

      legend.addTo(this.map);
    }
  }
};
</script>

<style>
#visualizar-mapa-layers-personalizados {
  height: 70vh;
  z-index: 0;
}

.mapa-info {
  padding: 6px 8px;
  font:
    14px/16px Arial,
    Helvetica,
    sans-serif;
  background: white;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}

.mapa-info h4 {
  margin: 0 0 5px;
  color: #777;
}

.mapa-legend {
  line-height: 18px;
  color: #555;
  max-height: 285px !important;
  overflow-y: auto !important;
}

.mapa-legend i {
  width: 18px;
  height: 18px;
  float: left;
  margin-right: 8px;
  opacity: 0.7;
}
</style>
